export const PHONE_INFO_BY_COUNTRY_CODE = {
  'LU': {
    'code': '+352',
    'format': '+352 XXX XXX',
  },
  'BE': {
    'code': '+32',
    'format': '+32 X XXX XX XX',
  },
  'FR': {
    'code': '+33',
    'format': '+33 X XX XX XX XX',
  },
};

export const TYPE_APPARTEMENT = 1;
export const TYPE_MAISON = 2;
export const TYPE_TERRAIN_A_BATIR = 3;
export const TYPE_ESPACE_COMMERCIAL = 4;
export const TYPE_ESPACE_DE_BUREAU = 5;

export const PROPERTY_TYPE_HOUSE = 'House';

export const COUNTRIES = {
  'FR': 'France',
  'BE': 'Belgium',
  'LU': 'Luxembourg',
};

export const ENTITY_TPBAT = 'TPBat';
export const ENTITY_TPHOME = 'TPHome';

export const COMPANY_TPHOME = 'Thomas & Piron HOME';
export const COMPANY_TPBAT = 'Thomas & Piron BATIMENT';

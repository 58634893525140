export const createContactInformation = (params) =>{
  const data = {
    type: 'CONTACT_INFORMATION',
    payload: params,
  };
  return data;
};

export const createContactFormInformation = (params) =>{
  const data = {
    type: 'CONTACT_FORM_INFORMATION',
    payload: params,
  };
  return data;
};

export const createContactData = (params) =>{
  let slotDate = null;
  let slotTime = null;
  if (params.dateAppointment) {
    const selectedYear = '' + params.dateAppointment.getFullYear();
    const selectedMonth = '' + (params.dateAppointment.getMonth()+1);
    const selectedDay = '' + params.dateAppointment.getDate();
    slotDate = selectedYear + '-' +
        selectedMonth.padStart(2, '0') + '-' +
        selectedDay.padStart(2, '0') + 'T00:00:00';
  }
  if (params.hour && params.minute) {
    slotTime = params.hour + ':' + params.minute + ':00';
  }
  const arrPurpose = {
    'ask_a_question': 'AskAQuestion',
    'build_my_house': 'BuildMyHouse',
    'buy_apartment': 'BuyAppartment',
    'renovate': 'Renovate',
    'buy_house': 'BuyHouse',
    'develop_a_project': 'DevelopProject',
    'visit_showroom': 'VisitShowroom',
    'visit_demonstration_house': 'VisitDemonstrationHouse',
    'visit_demonstration_apartment': 'ProjectRequestAppointment',
    'contact_about_property': 'ContactAboutProperty',
    'contact_about_project': 'ContactAboutProject',
    'contact_pre_project': 'PreProject',
    'pre_project_documentation': 'PreProjectDocumentation',
    'PropertyDocumentation': 'PropertyDocumentation',
  };
  const data = {
    type: 'CONTACT_DATA',
    payload: {
      'propertyData': {
        'id': params.projectId,
        'name': params.projectName,
        'url': window.location.href,
        'sociCode': params.sociCode,
      },
      'purpose': arrPurpose[params.purpose],
      'origin': '',
      'contactData': {
        'firstName': params.firstname,
        'lastName': params.lastname,
        'eMail': params.email,
        'phoneNumber': params.phone,
        'address': {
          'postCode': params.postalcode,
          'postCodeId': params.postalcodeId,
          'city': params.city,
          'cityId': params.cityId,
          'street': params.street,
          'number': params.streetNumber,
          'country': params.country,
          'countryId': params.countryId,
          'mailbox': params.mailbox,
        },
        'allowContactByEMail': false,
        'allowContactByPhone': false,
      },
      'message': (typeof (params.message) === 'undefined') ?
          null : params.message,
      'subscriptionData': {
        'subscribeForEmail': true,
        'subscribeForNewsLetter': true,
        'subscribeForSMS': true,
        'subscribeForSocialNetwork': true,
        'subscribeForPostalMail': true,
      },
      'dataProtectionAccepted': true,
      'forInvestment': (typeof (params.goal) === 'undefined') ?
      false : params.goal,
      'appointmentData': params.dateAppointment ? {
        'contactPointId': (typeof (params.contactId) === 'undefined') ?
            null : params.contactId,
        'slotDate': slotDate,
        'slotTime': slotTime,
      } : null,
    },
  };
  return data;
};
